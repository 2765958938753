// $primary-color: #f15a22;
$primary-color: #006ab4;
$secondary-color: #ff4d00;
$primary-font-color: #07101f;
$light-color: #fff;
$light-secondary-color: #ffa67f;
$extra-light-secondary-color: #ffa67f;
$dark-secondary: #8a8a8a;
$secondary-font-color: #6a7079;
$pale-red: #ffccbc;
$success-color: #198754;
$error-color: #d32f2f;
$info-color: #0288d1;
$warning-color: #e64a19;
$eerie-black: #1e1e1e;
$white: #ffffff;
$black: #000000;

//shades of blue
$blue: #4060b6;

//shades of grey
$whisper-grey: #eeeeee;
$light-grey: #f2f2f2;
$smoky-grey: #d0d0d0;
$bright-grey: #373f4b;
$grey-chateau: #989ca2;
$solitude-grey: #e9ebf0;
$dove-grey: #6c6c6c;
$placeholder-color: $grey-chateau;
$regent-grey: #7f8185;
$warm-grey: #878787;
$slate-grey: #67737d;

//shades of orange
$orange: #ff7a40;

//border and segment separators
$light-border-color: #c2c2c2;
$lighter-border-color: #cccccc;
$input-border-color: $lighter-border-color;
$border-color: $smoky-grey;
$segment-separator-color: $solitude-grey;

//background
$timber-wolf: #d2d5df;
$floral-white: #fcfbf9;
$lighter-timber-wolf: rgba($timber-wolf, 0.1);
$pattens-blue: #e5eaed;
$primary-background-color: #e5e5e5;
$page-background-color: #f8f9fa;
$button-grey-background: #f0f1f2;
$hover-background-color: #eaeaea;
$group-button-lighter-grey-background: $solitude-grey;
$segment-separator-secondary-color: $solitude-grey;
$footer-background-color: $floral-white;

$logo-color: #ff6404;

//lead status
$new-lead: lightseagreen;
$active-lead: skyblue;
$lost-lead: $grey-chateau;
$converted-lead: $success-color;
$allocated-lead: lightseagreen;
$in-progress-lead: #dddd10;

$navbar-bg: #fbcfbc;

//background-images
$square-of-dots: url(https://res.cloudinary.com/driv8kibp/image/upload/v1652767106/Youkraft%20assets/Group_48098247.svg);
